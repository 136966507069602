.toggleKitContainer {
  width: 46px;
  height: 26px;
  background-color: rgba(var(--icon-rgb), 0.4);
  border-radius: 23px;
  padding: 2px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out 0.1s;
  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
  &.onState {
    background-color: var(--primary);
    .toggleKitButton {
      transform: translateX(calc(100% - 2px));
    }
  }
  .toggleKitButton {
    transition: transform 0.2s ease-in-out;
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  }
}
