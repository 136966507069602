.commentListItem {
  padding: 12px;
  border-radius: 8px;
  background-color: var(--overlay-dark);
}
.commentListItemMessage {
  word-break: break-all;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  color: var(--text-secondary);
  // Override reset.scss styles
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: revert;
  }
  ol,
  ul {
    list-style: revert;
    padding: revert;
  }
  blockquote {
    border-left: 4px solid var(--divider);
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }
  .mention {
    border-radius: 20px;
    background-color: var(--ultra-light-primary);
    color: var(--text-secondary);
    padding: 2px 4px;
    &.currentUserMentioned {
      background-color: var(--primary);
      color: var(--text-base);
    }
  }
}
.commentListItemFooter {
  span {
    font-size: 11px;
    line-height: 1.4;
    font-weight: 400;
    color: var(--text-caption);
  }
}
