.datepickerKitFieldContainer {
  background-color: transparent;
  border-radius: 12px;
  padding: 0 12px;
  max-height: 48px;
  min-height: 48px;
  border: 0.5px solid var(--text-caption);
  transition: all 0.2s ease-in-out;
  &.filterView {
    height: 48px;
    border: 0.5px solid transparent;
    background-color: var(--overlay-dark);
    .datepickerFieldFormField {
      .mdc-floating-label mat-label {
        color: var(--text-secondary);
      }
    }
  }
  &.required {
    .mdc-floating-label mat-label {
      &::after {
        content: '*';
        display: inline-block;
        margin-left: 4px;
        color: var(--text-primary);
      }
    }
  }
  &.valueExist {
    border: 0.5px solid var(--text-caption);
  }
  &.invalidInput {
    border: 1px solid var(--red-primary);
    .mdc-floating-label mat-label {
      &::after {
        color: var(--red-primary);
      }
    }
  }
  &.disabledInput {
    border-color: var(--divider);
  }
  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    width: auto;
    height: auto;
  }
}
.datepickerFieldFormField {
  width: 100%;
  position: relative;
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input,
  .mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
    font-size: 14px;
    line-height: 1;
    color: var(--text-secondary);
  }
  input {
    &:disabled {
      color: var(--text-secondary);
    }
    &::placeholder,
    .DARK &::placeholder,
    .DARK_BLUE &::placeholder {
      color: rgba(var(--icon-rgb), 0.8);
    }
  }
  &.mat-form-field-disabled {
    opacity: 0.6;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled),
  .mdc-text-field--filled.mdc-text-field--disabled {
    background: none;
    padding: 0;
  }
  .mdc-line-ripple,
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 26px;
    mat-label {
      font-size: 14px;
      color: var(--text-caption);
      letter-spacing: normal;
    }
  }
  .mat-mdc-form-field-infix {
    padding: 0;
    padding-top: 20px;
    min-height: auto;
    height: 48px;
  }
  .mat-mdc-form-field-focus-overlay,
  .mat-mdc-icon-button .mat-mdc-button-ripple {
    display: none;
  }
  .iconsContainer {
    margin: 5px 0 0;
  }
  .datepickerFieldToggle {
    margin-top: 4px;
  }
}
