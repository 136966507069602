.textareaKitContainer {
  background-color: transparent;
  border-radius: 12px;
  position: relative;
  padding: 0 12px;
  min-height: 48px;
  border: 0.5px solid var(--text-caption);
  &.defaultHeight {
    height: 48px;
    overflow: hidden;
  }
  .hintContainer {
    padding-top: 12px;
  }
  .inputHint {
    padding: 0 14px;
    font-weight: 400;
    font-size: 11px;
    line-height: 1.4;
    color: var(--text-caption);
    position: absolute;
    bottom: -16px;
    left: 0;
    &.errorHint {
      color: var(--red-primary);
    }
  }
  &.required {
    .mat-mdc-floating-label mat-label {
      &::after {
        content: '*';
        display: inline-block;
        margin-left: 4px;
        vertical-align: top;
        color: var(--text-primary);
      }
    }
  }
  &.invalidInput {
    border: 1px solid var(--red-primary);
    .mat-mdc-floating-label mat-label {
      &::after {
        color: var(--red-primary);
      }
    }
  }
  &.focused {
    border: 1px solid var(--primary);
  }
}
.textareaFormField {
  width: 100%;
  font-size: 14px;
  position: relative;
  line-height: 1;
  color: var(--text-secondary);
}
.textareaFormField {
  textarea {
    max-height: 79px;
    overflow: hidden;
    &:disabled {
      color: var(--text-secondary);
    }
    &::placeholder,
    .DARK &::placeholder,
    .DARK_BLUE &::placeholder {
      color: rgba(var(--icon-rgb), 0.8);
    }
  }
  &.mat-form-field-disabled {
    opacity: 0.6;
  }
  .mat-mdc-floating-label mat-label {
    font-size: 14px;
    color: var(--text-caption);
  }
  .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control.mdc-text-field__input {
    font-size: 14px;
    color: var(--text-secondary);
  }
  .mat-mdc-text-field-wrapper.mdc-text-field {
    background: none;
    padding: 0;
  }
  .mat-mdc-form-field-focus-overlay,
  .mdc-line-ripple,
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mat-mdc-form-field-infix {
    min-height: 48px;
    padding: 0;
    padding-top: 20px;
  }
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 22px;
  }
  &.valueExist {
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 26px;
    }
  }
}
