.inputWithChipsKitContainer {
  background-color: transparent;
  border-radius: 12px;
  padding: 0 12px;
  border: 0.5px solid var(--text-caption);
  height: 48px;
  max-height: 112px;
  position: relative;
  &.defaultHeight {
    height: 48px !important;
    .chipListKit {
      overflow: hidden;
    }
  }
  &.required {
    .mat-mdc-floating-label mat-label {
      &::after {
        content: '*';
        display: inline-block;
        margin-left: 4px;
        vertical-align: top;
        color: var(--text-primary);
      }
    }
  }
  &.valueExist {
    height: 100%;
  }
  &.invalidInput {
    border: 1px solid var(--red-primary);
    .mat-mdc-floating-label mat-label {
      &::after {
        color: var(--red-primary);
      }
    }
  }
  .chipListKit {
    width: 100%;
    padding-left: 6px;
    padding-bottom: 6px;
    margin-top: -3px;
    .mdc-evolution-chip-set__chips {
      max-height: 79px;
      padding-right: 25px;
      overflow-y: auto;
    }
  }
  .chipKit {
    box-sizing: border-box;
    padding: 4px 12px !important;
    border-radius: 16px;
    min-height: 24px;
    height: 24px;
    margin: 2px !important;
    max-width: 230px;
    &.resetPadding {
      padding: 4px 8px 4px 12px !important;
    }
    .mat-mdc-chip-focus-overlay,
    .mat-mdc-chip-graphic,
    .mat-mdc-chip-action::before,
    .cdk-visually-hidden,
    .mat-ripple {
      display: none;
    }
    .mdc-evolution-chip__cell--primary,
    .mat-mdc-chip-action-label,
    .mat-mdc-chip-action {
      overflow: hidden;
    }
    .mat-mdc-chip-action,
    &.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary,
    &.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--trailing {
      padding: 0;
    }
    &.addButton {
      background-color: var(--ultra-light-primary);
      cursor: pointer;
      .chipLink {
        color: var(--primary);
      }
      .mdc-evolution-chip__action--primary {
        padding-right: 0;
      }
    }
    .chipLink {
      font-size: 11px;
      line-height: 1.4;
      font-weight: 400;
      text-decoration: none;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    a.chipLink {
      color: var(--primary);
      @media (hover: hover) {
        &:hover {
          color: var(--primary);
        }
      }
    }
    span.chipLink {
      color: var(--text-secondary);
    }
  }
  .inputFormField {
    width: 100%;
    font-size: 14px;
    position: relative;
    line-height: 1;
    color: var(--text-secondary);
  }
  .inputFormField {
    input {
      &:disabled {
        color: var(--text-secondary);
      }
      &::placeholder,
      .DARK &::placeholder,
      .DARK_BLUE &::placeholder {
        color: rgba(var(--icon-rgb), 0.8);
      }
    }
    &.mat-form-field-disabled {
      opacity: 0.6;
    }
    .mat-mdc-form-field-infix {
      padding-top: 18px;
    }
  }
  .iconsContainer {
    position: absolute;
    padding: 0;
    margin: 0;
    top: 12px;
    right: 0;
  }
  .inputHint {
    padding: 0 14px;
    font-weight: 400;
    font-size: 11px;
    line-height: 1.4;
    color: var(--text-caption);
    &.errorHint {
      color: var(--red-primary);
    }
  }
  &.focused {
    border: 0.5px solid var(--primary);
  }
  &.filterView {
    border: 0.5px solid transparent;
    background-color: var(--overlay-dark);
    .chipKit {
      background-color: var(--ultra-light-primary);
    }
  }
  &.disabledInput {
    border-color: var(--divider);
  }
}
