.multiselectDropdownContainer {
  position: relative;
  width: 100%;
  .caption {
    position: absolute;
    top: 2px;
    left: 12px;
    z-index: 1;
  }
  .hasBackground {
    .multiselect-dropdown {
      .dropdown-btn {
        border: 1px solid transparent !important;
        background-color: var(--overlay-dark) !important;
      }
      .selected-item {
        background-color: var(--surface) !important;
      }
    }
  }
}
.multiselect-dropdown {
  .disabled {
    pointer-events: none;
    .selected-item a,
    .dropdown-multiselect__caret {
      display: none !important;
    }
  }
  .dropdown-btn {
    min-height: 48px;
    padding: 14px 12px 8px !important;
    border-radius: 12px !important;
    border: 1px solid var(--divider) !important;
    font-size: 14px;
    background: none !important;
  }
  .dropdown-list {
    overflow: hidden;
    margin-top: 8px !important;
    padding: 0 !important;
    border-radius: 8px !important;
    box-shadow:
      0 8px 18px rgba(0, 0, 0, 0.02),
      0 18px 36px rgba(0, 0, 0, 0.04) !important;
    background-color: var(--option) !important;
    width: 98% !important;
    border: none !important;
    left: 50%;
    transform: translateX(-50%);
  }
  .dropdown-list .filter-textbox {
    padding: 10px 16px !important;
    input {
      color: var(--text-secondary);
      padding: 0 !important;
      &::placeholder {
        color: var(--text-caption);
      }
    }
  }
  .selected-item {
    display: flex;
    align-items: center;
    padding: 4px 12px !important;
    border-radius: 16px !important;
    min-height: 24px !important;
    height: 24px !important;
    border: none !important;
    background-color: var(--overlay-dark) !important;
    color: var(--text-secondary) !important;
    font-size: 11px;
    line-height: 1.4;
    cursor: default;
    span {
      white-space: nowrap;
    }
    a {
      color: var(--text-secondary) !important;
      font-size: 0;
      line-height: 1;
      cursor: pointer;
      &::after {
        font-family: 'icons', Fallback, sans-serif;
        color: var(--icon) !important;
        content: '\e93d' !important;
        font-size: 16px;
      }
    }
    &:hover {
      box-shadow: none !important;
      background-color: var(--ultra-light-primary) !important;
      a::after {
        color: var(--primary) !important;
      }
    }
  }
  .dropdown-multiselect__caret {
    height: 48px !important;
    display: flex !important;
    align-items: center;
    &::before {
      border: none !important;
      font-family: 'icons', Fallback, sans-serif;
      color: var(--icon) !important;
      content: '\e95a' !important;
      font-size: 24px;
      top: 0 !important;
      margin: 0 !important;
    }
  }
  .multiselect-item-checkbox {
    input:disabled + div {
      opacity: 0.6;
    }
  }
  .multiselect-item-checkbox,
  .no-filtered-data,
  .no-data {
    font-size: 14px;
    line-height: 1.2;
    padding: 10px 16px !important;
    input[type='checkbox']:checked + div::before {
      background-color: var(--primary) !important;
      animation: none !important;
    }
    div {
      color: var(--text-secondary) !important;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 18px;
      &::before {
        border-radius: 5px !important;
        border: 1px solid var(--divider) !important;
        width: 16px !important;
        height: 16px !important;
      }
      &::after {
        background-color: var(--primary) !important;
      }
    }
    &:hover {
      background-color: var(--option-hover) !important;
      div {
        color: var(--primary) !important;
      }
    }
  }
  .no-filtered-data,
  .no-data {
    h5 {
      font-size: 14px;
      line-height: 1.2;
      color: var(--text-caption);
      font-weight: 400;
    }
  }
}
