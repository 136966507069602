import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GlobalStoreService } from '@services/internal/global-store.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-select-items-count',
  templateUrl: './select-items-count.component.html',
  styleUrls: ['./select-items-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class SelectItemsCountComponent {
  @Input() selectedCount: number = 0;
  @Input() totalCount: number = 0;
  @Input() ind: number = 0;
  @Input() backgroundTransparent: boolean = false;
  @Input() allowZeroValue: boolean = false;
  @Input() showExpectedQuantities: boolean = true;
  @Input() validateMaxCount: boolean = true;
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() disableInput: boolean = false;

  @Output() changeCount = new EventEmitter<number>();

  readonly isMobile$: BehaviorSubject<boolean> = GlobalStoreService.isMobile$;

  /**
   * Calculate +1 or -1 when user click +- buttons on UI
   */
  calculate(isIncrement: boolean = false): void {
    const count = isIncrement ? this.selectedCount + 1 : this.selectedCount - 1;
    this.changeCount.emit(count);
  }

  validationMaxNumber(value: number, maxValue: number): number {
    let selectedCount: number;
    if (Number(value) === 0) {
      selectedCount = 1;
    } else {
      if (this.validateMaxCount) {
        selectedCount = Number(value) >= Number(maxValue) ? maxValue : value;
      } else {
        selectedCount = value;
      }
    }
    this.changeCount.emit(selectedCount);
    return selectedCount;
  }

  editCount(value: string | number): void {
    this.changeCount.emit(Number(value));
  }
}
