<div
  data-layout="row"
  data-flex-xs="100"
  data-layout-align="space-between center"
  class="calculateButtons"
  [ngClass]="{ backgroundTransparent: backgroundTransparent }"
  data-layout-gap="8px"
>
  <app-icon
    *ngIf="!disabled"
    data-cy="decreaseInventoryCountButton"
    [class.disabled]="selectedCount < (allowZeroValue ? 1 : 2)"
    [icon]="'minus'"
    [type]="'outline'"
    [size]="(isMobile$ | async) ? 24 : 16"
    [disabled]="loading"
    (click)="$event.stopPropagation(); calculate(false)"
  ></app-icon>

  <div data-layout="row" data-layout-align="start center">
    <div class="calculateInputContainer">
      <input
        matInput
        #input
        [disabled]="disabled || loading || disableInput"
        autocomplete="false"
        [(ngModel)]="selectedCount"
        (keyup)="editCount(selectedCount)"
        (blur)="editCount(validationMaxNumber(selectedCount, totalCount))"
        [max]="totalCount"
        thousandSeparator=","
        [dropSpecialCharacters]="true"
        placeholder="0"
        mask="separator"
        maxlength="6"
        data-cy="inventoryCountInput"
        class="calculateInput"
      />
    </div>

    <span *ngIf="showExpectedQuantities" class="calculateTotalCount"
      >/
      <span
        data-cy="availableItemsCount"
        matTooltipPosition="below"
        matTooltipClass="connectSxTooltipMessage"
        [matTooltipDisabled]="totalCount <= 999"
        [matTooltip]="totalCount.toString()"
        >{{ totalCount > 8999 ? '9k+' : totalCount }}</span
      >
    </span>
  </div>

  <app-icon
    *ngIf="!disabled"
    data-cy="incInventoryCountButton"
    [class.disabled]="selectedCount >= totalCount"
    [color]="'primary'"
    [icon]="'plus'"
    [type]="'outline'"
    [size]="(isMobile$ | async) ? 24 : 16"
    [disabled]="loading"
    (click)="$event.stopPropagation(); calculate(true)"
  ></app-icon>
</div>
