import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  EventContainerModel,
  EventModel,
  ContactModel,
  ManufacturerModel,
  NoteModel,
  PageableModel,
  PONumberModel,
  PriceAdjustmentModel,
  ProductLineModel,
  SimilarInventoryInfo,
  ContainerOnReserveModel
} from '@shared/models';
import { HttpHelperService } from './internal/http-helper.service';
import { ImageModel } from '@shared/models/shared/image.model';
import { AuthenticationService } from './auth.service';
import { EventPageableParamsBuilder } from '@shared/models/build-models';
import { IDutUpdateDevicePrice, ISubmitForBilling, IUpdateDutStatus } from '@shared/interfaces';
import { catchError, map, Observable, of } from 'rxjs';
import { MarkUsedInventoryFilter } from '@shared/models/features/events/mark-used-inventory-state';
import { ApiService } from '@shared/classes/api-service';
import { EventSaveRequestBuild } from '@shared/models/build-models/events/event-save-request-build';
import { HeaderOptionsWithToken } from '@shared/utils/http/build-http-header-with-token';
import { environment } from '@environment';
import { EEventCreditStatus } from '@shared/enum';
import { EventActionModel } from '@shared/models/features/events/event-action.model';
import { IHandWrittenSimilarInventoryInfoResponse, ISupposedInventories } from '@shared/interfaces/events/hand-written-inventories-info';
import { IEventInfoMessaging } from '@shared/interfaces/events/event-info-messaging';
import { IAddPONumberDTO } from '@shared/interfaces/events/po-number-dto';
import { IEventAssignedInventoryResponse } from '@shared/interfaces/events/event-assigned-inventory-responce';

@Injectable({
  providedIn: 'root'
})
export class EventsService extends ApiService {
  addComment(id: string, message: string, userIds: string[]): Observable<boolean> {
    return this.post<void>(`events/${id}/notes?mentionUserIds=${userIds}`, message).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getActions(id: string): Observable<EventActionModel> {
    return this.get<EventActionModel>(`event-tracking-action/${id}`);
  }

  editComment(
    id: string,
    commentId: string,
    message: string,
    userIds: string[],
    commentUserIds: string[],
    isPdfPrint: boolean = false
  ): Observable<boolean> {
    return this.put<void>(
      `events/${id}/note/${commentId}?newMentionUserIds=${userIds}&existMentionUserIds=${commentUserIds}&isPdfPrint=${isPdfPrint}`,
      message
    ).pipe(
      map(() => {
        const msgKey = isPdfPrint ? 'shared.alerts.successMessages.printCommentInPDF' : 'shared.alerts.successMessages.saved';
        this.alertsService.showSuccess(msgKey);
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  assignInventories(id: string, body: SimilarInventoryInfo[]): Observable<void> {
    return this.post<void>(`events/${id}/inventories/assign`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.success');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  assignManufacturers(eventId: string, ids: string[]): Observable<string> {
    return this.post<string>(`events/${eventId}/manufacturers/assign`, ids).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.assignManufacturer');
        return of(null);
      })
    );
  }

  assignProducts(eventId: string, ids: string[]): Observable<string> {
    return this.post<string>(`events/${eventId}/products/assign`, ids).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.assignProductLine');
        return of(null);
      })
    );
  }

  assignContainers(eventId: string, ids: string[]): Observable<string> {
    return this.post<string>(`events/${eventId}/containers/assign`, ids).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.assignContainer');
        return of(null);
      })
    );
  }

  assignFacilityContact(eventId: string, ids: string[]): Observable<void> {
    return this.post<void>(`events/${eventId}/facilityContacts/assign`, ids).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.assignFacilityContact');
        return of(null);
      })
    );
  }

  cancel(eventId: string): Observable<boolean> {
    return this.post<void>(`events/${eventId}/cancel`, { eventId }).pipe(
      map(() => true),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  changeFacilityStockStatus(eventId: string, facilityStockEvent: boolean): Observable<boolean> {
    return this.put<void>(`events/${eventId}/facilityStockEvent?facilityStockEvent=${facilityStockEvent}`, {}).pipe(
      map(() => true),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }

  completeEvent(eventId: string): Observable<boolean> {
    return this.put<void>(`events/${eventId}/completed`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.success');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }

  completeStockAdjustment(eventId: string): Observable<boolean> {
    return this.put<void>(`events/${eventId}/inventoryAdjusted`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.success');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }

  createPriceAdjustment(eventId: string, adjustment: PriceAdjustmentModel): Observable<boolean> {
    return this.post<string>(`events/${eventId}/priceAdjustment`, adjustment).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.createPriceAdjustments');
        return of(false);
      })
    );
  }

  deletePriceAdjustment(eventId: string, priceAdjustmentId: string): Observable<void> {
    return this.delete<void>(`events/${eventId}/priceAdjustment/${priceAdjustmentId}`).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.saved')),
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.deletePriceAdjustments');
        return of(false);
      })
    );
  }

  getEvent(id: string, redirectToNotFoundPage: boolean = false): Observable<EventModel> {
    return this.get<EventModel>(`events/${id}`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        HttpHelperService.errorHandler(error, null, redirectToNotFoundPage);
        return of(null);
      })
    );
  }

  getEventMessaging(id: string): Observable<IEventInfoMessaging> {
    return this.get<IEventInfoMessaging>(`events/${id}/event-info-messaging`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  getAssignedInventories(id: string): Observable<IEventAssignedInventoryResponse> {
    return this.get<IEventAssignedInventoryResponse>(`events/${id}/inventories`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.loadAssignedInventories', error.message);
        return of([]);
      })
    );
  }

  getEventsValue(params: EventPageableParamsBuilder): Observable<number> {
    return this.get<number>(`events/value`, HttpHelperService.addResponseTypeJSON(params));
  }

  getTotalBilled(params: EventPageableParamsBuilder): Observable<number> {
    return this.get<number>(`events/billed-value`, HttpHelperService.addResponseTypeJSON(params));
  }

  getImages(id: string): Observable<ImageModel[]> {
    return this.get<ImageModel[]>(`events/${id}/images`);
  }

  getAttachments(id: string): Observable<ImageModel[]> {
    return this.get<ImageModel[]>(`events/${id}/attachment`);
  }

  getInventoriesToDut(eventId: string, params: MarkUsedInventoryFilter, size: number = 20): Observable<SimilarInventoryInfo[]> {
    return this.post<SimilarInventoryInfo[]>(`events/${eventId}/inventories/search?size=${size}`, params);
  }

  getManufacturers(id: string): Observable<ManufacturerModel[]> {
    return this.get<ManufacturerModel[]>(`events/${id}/manufacturers`);
  }

  getNotes(id: string): Observable<NoteModel[]> {
    return this.get<NoteModel[]>(`events/${id}/notes`);
  }

  getPageable(params: EventPageableParamsBuilder): Observable<PageableModel<EventModel>> {
    return this.get<PageableModel<EventModel>>(`events/pageable/v1`, HttpHelperService.addResponseTypeJSON(params));
  }

  getPoNumbers(id: string): Observable<PONumberModel[]> {
    return this.get<PONumberModel[]>(`events/${id}/ponumber`).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => of([]))
    );
  }

  getPriceAdjustment(eventId: string): Observable<PriceAdjustmentModel[]> {
    return this.get<PriceAdjustmentModel[]>(`events/${eventId}/priceAdjustments`).pipe(
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.getPriceAdjustments');
        return of([]);
      })
    );
  }

  getProducts(eventId: string): Observable<ProductLineModel[]> {
    return this.get<ProductLineModel[]>(`events/${eventId}/products`);
  }

  getContainers(eventId: string): Observable<EventContainerModel[]> {
    return this.get<EventContainerModel[]>(`events/${eventId}/eventContainers`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  getFacilityContacts(eventId: string): Observable<ContactModel[]> {
    return this.get<ContactModel[]>(`events/${eventId}/facilityContacts`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  markPaid(eventId: string): Observable<boolean> {
    return this.put<void>(`events/${eventId}/paid`, {}).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.markedPaid');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }

  createEvent(body: EventSaveRequestBuild): Observable<string> {
    return this.post<string>(`events`, body).pipe(
      map(id => {
        this.alertsService.showSuccess('shared.alerts.successMessages.created');
        return id;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }

  updateEvent(id: string, body: EventSaveRequestBuild): Observable<boolean> {
    return this.put<void>(`events/${id}`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  reopen(id: string): Observable<void> {
    return this.post<void>(`events/${id}/dut/reopen`, id).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  savePONumbers(id: string, body: IAddPONumberDTO): Observable<boolean> {
    return this.post<void>(`events/${id}/ponumber`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  updatePO(id: string, body: IAddPONumberDTO): Observable<boolean> {
    return this.put<void>(`events/${id}/updatePO`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  removePONumber(id: string): Observable<boolean> {
    return this.delete<void>(`events/${id}/ponumber`).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.deleted');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  removeReusableDevice(id: string, inventoryIds: string[]): Observable<boolean> {
    return this.delete<void>(`events/${id}/reusable-inventories/remove`, { body: inventoryIds }).pipe(
      map(() => true),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  submit(id: string, body: ISubmitForBilling): Observable<boolean> {
    return this.post<void>(`events/${id}/dut/submit`, body).pipe(
      map(() => true),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  unAssignContainers(eventId: string, ids: string[]): Observable<string> {
    return this.post<string>(`events/${eventId}/containers/unassign`, ids).pipe(
      map(() => true),
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.unAssignContainer');
        return of(null);
      })
    );
  }

  unAssignFacilityContact(eventId: string, ids: string[]): Observable<string> {
    return this.post<string>(`events/${eventId}/facilityContacts/unassign`, ids).pipe(
      map(() => true),
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.unAssignFacilityContact');
        return of(null);
      })
    );
  }

  updateDutStatus(id: string, body: IUpdateDutStatus): Observable<boolean> {
    return this.put<void>(`events/mobile/${id}/inventories/markused`, body).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }

  unAssignDevicesFromEvent(eventId: string, inventoryIds: string[]): Observable<boolean> {
    return this.post<void>(`events/${eventId}/inventories/bulk-unassign`, inventoryIds).pipe(
      map(() => true),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }

  updatePrice(id: string, body: IDutUpdateDevicePrice): Observable<void> {
    return this.put<void>(`events/mobile/${id}/inventories/price`, body).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.saved')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.savePrice', error.message);
        return of(false);
      })
    );
  }

  updatePriceAdjustment(eventId: string, adjustment: PriceAdjustmentModel): Observable<boolean> {
    return this.put<void>(`events/${eventId}/priceAdjustment`, adjustment).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((_error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError('shared.alerts.errorMessages.updatePriceAdjustments');
        return of(false);
      })
    );
  }

  deleteSticker(eventId: string): Observable<void> {
    return this.delete<void>(`events/${eventId}/sticker`).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.deleted');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }

  deleteImage(eventId: string, imageIds: string[]): Observable<void> {
    return this.delete<void>(`events/${eventId}/images`, HeaderOptionsWithToken(imageIds)).pipe(
      map(() => this.alertsService.showSuccess('shared.alerts.successMessages.deleted')),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }

  deleteAttachments(attachmentId: string): Observable<boolean> {
    return this.delete<void>(`events/attachment?eventAttachmentId=${attachmentId}`).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.deleted');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }

  async uploadSticker(eventId: string, formData: FormData): Promise<void> {
    const token: string = AuthenticationService.getToken();

    const res = await window.fetch(`${environment.apiUrlCore}events/${eventId}/sticker`, {
      method: 'PUT',
      body: formData,
      headers: { Authorization: `Bearer ${token}` }
    });
    if (res.status === 200) {
      Promise.resolve(res).then();
      this.alertsService.showSuccess('shared.alerts.successMessages.imageWasUploaded');
    } else {
      this.alertsService.showError('shared.alerts.errorMessages.loadImages');
    }
  }

  async uploadImage(eventId: string, formData: FormData, showSuccessMessage: boolean = true): Promise<boolean> {
    const token: string = AuthenticationService.getToken();

    const res = await window.fetch(`${environment.apiUrlCore}events/${eventId}/image`, {
      method: 'PUT',
      body: formData,
      headers: { Authorization: `Bearer ${token}` }
    });
    if (res.status === 200) {
      Promise.resolve(res);
      if (showSuccessMessage) {
        this.alertsService.showSuccess('shared.alerts.successMessages.imageWasUploaded');
      }
      return true;
    } else {
      this.alertsService.showError('shared.alerts.errorMessages.loadImages');
      return false;
    }
  }

  async uploadAttachments(eventId: string, formData: FormData, _showSuccessMessage: boolean = true): Promise<boolean> {
    const token: string = AuthenticationService.getToken();

    const res = await window.fetch(`${environment.apiUrlCore}events/${eventId}/attachment`, {
      method: 'PUT',
      body: formData,
      headers: { Authorization: `Bearer ${token}` }
    });
    if (res.status === 200) {
      Promise.resolve(res);
      return true;
    } else {
      this.alertsService.showError('shared.alerts.errorMessages.loadImages');
      return false;
    }
  }

  uploadAttachmentsByUrl(id: string, url: string): Observable<boolean> {
    return this.put<string>(`events/${id}/attachment/url`, url).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }

  async uploadProviderSignImage(eventId: string, image: FormData): Promise<boolean> {
    return window
      .fetch(`${environment.apiUrlCore}events/${eventId}/providerSignImage`, HttpHelperService.setImageParams('PUT', image))
      .then(() => true)
      .catch(() => {
        this.alertsService.showError('shared.alerts.errorMessages.saveSignature');
        return null;
      });
  }

  async uploadRepresentativeSignImage(eventId: string, image: FormData): Promise<boolean> {
    return window
      .fetch(`${environment.apiUrlCore}events/${eventId}/repSignImage`, HttpHelperService.setImageParams('PUT', image))
      .then(() => true)
      .catch(() => {
        this.alertsService.showError('shared.alerts.errorMessages.saveSignature');
        return null;
      });
  }

  noCharge(eventId: string, inventoryIds: string[], noCharge: boolean): Observable<boolean> {
    return this.put<void>(`events/${eventId}/nocharge`, inventoryIds, HttpHelperService.addResponseTypeJSON({ noCharge })).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }

  readyForUse(eventId: string, readyForUse: boolean = true): Observable<boolean> {
    return this.put<void>(`events/${eventId}/readyForUse`, {}, HttpHelperService.addResponseTypeJSON({ readyForUse })).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }

  setEventCreditStatus(eventId: string, eventCreditStatus: keyof typeof EEventCreditStatus = null): Observable<boolean> {
    return this.put<void>(`events/${eventId}/event-credit-status`, {}, HttpHelperService.addResponseTypeJSON({ eventCreditStatus })).pipe(
      map(() => {
        this.alertsService.showSuccess('shared.alerts.successMessages.saved');
        return true;
      }),
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(false);
      })
    );
  }

  searchHandWrittenSimilar(
    eventId: string,
    params: ISupposedInventories[],
    isFacilityStock: boolean
  ): Observable<IHandWrittenSimilarInventoryInfoResponse[]> {
    return this.post<IHandWrittenSimilarInventoryInfoResponse[]>(
      `events/${eventId}/inventories/hand-written-search?isFacilityStock=${isFacilityStock}`,
      params
    ).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  getContainersOnReserve(eventId: string): Observable<ContainerOnReserveModel[]> {
    return this.get<ContainerOnReserveModel[]>(`events/${eventId}/reserved-containers`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of([]);
      })
    );
  }

  /** @return
   * true - transfer created & devices added to event
   * false - processing
   * null - no quick transfer were created
   */
  isDevicesUsedProcessedAfterQuickTransferCreation(eventId: string): Observable<boolean> {
    return this.get<boolean>(`events/${eventId}/is-devices-used-processed`).pipe(
      catchError((error: HttpErrorResponse, _caught: Observable<any>) => {
        this.alertsService.showError(null, error.message);
        return of(null);
      })
    );
  }
}
