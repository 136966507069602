import { Injectable } from '@angular/core';
import { EventsService } from '@services/events.service';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  finalize,
  firstValueFrom,
  map,
  Observable,
  take,
  takeUntil,
  tap
} from 'rxjs';
import {
  EventModel,
  ContactModel,
  InventoryModel,
  ManufacturerModel,
  PONumberModel,
  ProcedureModel,
  PreferenceCardAssignment,
  StatusesCount
} from '@shared/models';
import { DataPassService } from '@services/internal/datapass.service';
import { TEventPaidStatus, TEventType, TSalesOrderStatus } from '@shared/type/index.type';
import { UsersService } from '@services/users.service';
import { AlertsService } from '@services/internal/alerts.service';
import { DestroySubscriptions } from '@shared/classes/destroy-subscriptions';
import { IEventBuildParams, IFilter } from '@shared/interfaces';
import { EventSaveRequestBuild } from '@shared/models/build-models/events/event-save-request-build';
import { EventsState } from '../helpers/event.store.model';
import { ConfirmModalConfig, CreateMediumEntityConfig, CreateSmallEntityConfig } from '@constants';
import { PoNumberComponent } from '../partials/event-detail/modals/po-number/po-number.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RemoveDuplicateArrayById } from '@shared/utils/arrays/remove-duplicate-array-by-id';
import { LanguageService } from '@services/internal/language.service';
import { ProcedureService } from '@services/procedure.service';
import { PermissionService } from '@services/internal/permission.service';
import { UserAssignmentsService } from '@services/user-assignments.service';
import { EventQuotesService } from '@services/event-quotes.service';
import { CreateQuoteComponent } from '../../event-quote/modals/create-quote/create-quote.component';
import { ConfirmComponent } from '@shared/components/modals/confirm/confirm.component';
import { PageEvent } from '@angular/material/paginator';
import { ManagerSearchParamsService } from '@shared/helpers/managaer-search-params/manager-search-params.service';
import { RequestRecipesService } from '@services/request-recipes.service';
import { EventQuoteParamsModel } from '@shared/models/features/events/event-quote.model';
import { AiPredictionsService } from '@services/ai-predictions.service';
import { isValidFile } from '@shared/utils/uploaded-file-validation';
import { LocalStorage } from '@services/internal/localstorage.service';
import { RequestPageableSearchParams } from '@shared/models/build-models';
import { RequestsService } from '@services/requests.service';

@Injectable({ providedIn: 'root' })
export class EventsStoreService extends DestroySubscriptions {
  store$: BehaviorSubject<EventsState> = new BehaviorSubject<EventsState>(new EventsState());

  constructor(
    private eventsService: EventsService,
    private dataPassService: DataPassService,
    private alertsService: AlertsService,
    private dialog: MatDialog,
    private router: Router,
    private procedureService: ProcedureService,
    private permissionService: PermissionService,
    private userAssignmentsService: UserAssignmentsService,
    private eventQuotesService: EventQuotesService,
    private managerSearchParamsService: ManagerSearchParamsService,
    private preferenceCardService: RequestRecipesService,
    private aiPredictionsService: AiPredictionsService,
    private requestsService: RequestsService
  ) {
    super();
    this.destroy();

    this.store$.next({ ...this.store$.value, ...{ allowEdit: this.permissionService.isGranted('events', 'canEditEvent') } });

    this.dataPassService
      .getCanDistributorEdit()
      .pipe(takeUntil(this.subscriptions))
      .subscribe((result: boolean) => {
        this.store$.next({ ...this.store$.value, ...{ isDistributorCanEdit: result } });
        this.checkDisableEvent();
      });

    combineLatest([
      this.store$.pipe(
        map(st => st.event),
        distinctUntilChanged()
      ),
      this.store$.pipe(
        map(st => st.manufacturers),
        distinctUntilChanged()
      ),
      this.store$.pipe(
        map(st => st.formsValid),
        distinctUntilChanged()
      ),
      this.store$.pipe(
        map(st => st.formsTouched),
        distinctUntilChanged()
      ),
      this.store$.pipe(
        map(st => st.productLines),
        distinctUntilChanged()
      ),
      this.store$.pipe(
        map(st => st.facilityContacts),
        distinctUntilChanged()
      )
    ])
      .pipe(
        tap(() => {
          this.checkValidation();
        }),
        takeUntil(this.subscriptions)
      )
      .subscribe();
  }

  getPriceAdjustment(): void {
    if (!this.store$.value.eventId) {
      return;
    }

    this.eventsService
      .getPriceAdjustment(this.store$.value.eventId)
      .pipe(take(1))
      .subscribe(prices => {
        this.store$.next({ ...this.store$.value, ...{ priceAdjustments: prices } });
        this.store$.next({
          ...this.store$.value,
          ...{ priceAdjustmentsExist: Boolean(prices.length) }
        });
      });
  }

  async loadAssignedDevices(): Promise<boolean> {
    if (!this.store$.value.eventId) {
      return false;
    }

    this.store$.next({ ...this.store$.value, ...{ loadingAssignedDevices: true } });
    return firstValueFrom(this.eventsService.getAssignedInventories(this.store$.value.eventId))
      .then(data => {
        const { inventories, packs } = data;
        const inventoriesReversed = inventories.reverse();
        this.store$.next({ ...this.store$.value, ...{ assignedDevicesOriginal: inventoriesReversed } });
        this.store$.next({ ...this.store$.value, ...{ assignedDevicesGrouped: this.adaptedDataForView(inventoriesReversed) } });
        this.store$.next({ ...this.store$.value, ...{ assignedPacksOriginal: packs } });
        this.store$.next({ ...this.store$.value, ...{ existAssignedInventories: Boolean(inventories.length) || Boolean(packs.length) } });
        this.checkDevicePriceChanged(inventories);
        this.getPriceAdjustment();
        this.checkDuplicates(inventories);
        this.checkDuplicates(packs);
        this.sumDeviceStatuses();
        this.checkNoCharge();
        return !!inventories.length && !!packs.length;
      })
      .finally(() => this.store$.next({ ...this.store$.value, ...{ loadingAssignedDevices: false } }));
  }

  removePONumber(): void {
    this.eventsService
      .removePONumber(this.store$.value.eventId)
      .pipe(take(1))
      .subscribe(() => {
        this.getPoNumbers();
        this.getData();
      });
  }

  updateProcedureModifiers(procedure: ProcedureModel, modifiers: string[]): void {
    this.procedureService
      .updateProcedure(procedure.id, {
        name: procedure.name,
        modifiers: [...(procedure.modifiers || []), ...modifiers],
        type: procedure.type
      })
      .pipe(take(1))
      .subscribe();
  }

  loadManufacturers(): void {
    this.eventsService
      .getManufacturers(this.store$.value.eventId)
      .pipe(take(1))
      .subscribe(manufacturers => {
        this.store$.next({ ...this.store$.value, ...{ manufacturers } });
      });
  }

  loadContainers(): void {
    if (this.store$.value.event?.eventType === 'STOCKING_ORDER' || !this.store$.value.eventId?.length) {
      return;
    }
    this.eventsService
      .getContainers(this.store$.value.eventId)
      .pipe(take(1))
      .subscribe(eventContainers => {
        eventContainers.forEach(
          eventContainer => (eventContainer.inventory.autoassignedContainer = eventContainer.eventContainerType === 'AUTOMATICALLY')
        );
        this.store$.next({ ...this.store$.value, ...{ containers: eventContainers.map(c => c.inventory) } });
      });
  }

  loadFacilityContacts(checkContactAvailability: boolean = false): void {
    this.eventsService
      .getFacilityContacts(this.store$.value.eventId)
      .pipe(take(1))
      .subscribe(facilityContacts => {
        facilityContacts = facilityContacts.map(contact => {
          if (!contact.name) {
            contact.name = LanguageService.instant('events.detail.untitledFacilityContactName');
          }
          return contact;
        });
        this.store$.next({ ...this.store$.value, ...{ facilityContacts } });

        if (!facilityContacts.length && checkContactAvailability && this.store$.value.event.eventType === 'STOCKING_ORDER') {
          this.alertsService.showWarning('shared.alerts.errorMessages.lastFacilityContact');
        }
      });
  }

  destroy(): void {
    this.store$.next(new EventsState());
  }

  addManufacturers(manufacturer: ManufacturerModel[]): void {
    const manufacturers = this.store$.value.manufacturers;
    const commonManufacturers = [...manufacturers, ...manufacturer];
    this.store$.next({ ...this.store$.value, ...{ manufacturers: commonManufacturers } });
  }

  selectModifier(value: string): void {
    const modifiers: { modifierName: string }[] = this.store$.value.modifiers;

    if ((value || '').trim()) {
      modifiers.push({ modifierName: value.trim() });
    }

    this.store$.next({ ...this.store$.value, ...{ modifiers } });
  }

  addPONumber(): void {
    const PONumbers: PONumberModel[] = [];
    const selectedManufacturers: ManufacturerModel[] = this.store$.value.manufacturers;
    selectedManufacturers.forEach(m => {
      let exist: boolean = false;
      this.store$.value.poNumbers.every((po): boolean | void => {
        if (po.manufacturer.id === m.id) {
          PONumbers.push(po);
          exist = true;
        } else {
          return true;
        }
      });
      if (!exist) {
        const poNumb = new PONumberModel();
        poNumb.manufacturer = m;
        PONumbers.push(poNumb);
      }
    });

    const dialogConfig: MatDialogConfig = { ...CreateSmallEntityConfig };
    dialogConfig.data = {
      eventId: this.store$.value.eventId,
      poNumbers: PONumbers
    };
    const dialogRef = this.dialog.open(PoNumberComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(async result => {
        if (!result) {
          return;
        }

        this.toggleLoading(true);

        /** Safe PO numbers if it was before (updatePO doesn't change the event status)
         * or create using separated endpoint (changes event status from CLOSED to COMPLETED) */
        if (this.store$.value.poNumbers?.length && this.store$.value.event.eventStatus === 'PAID') {
          await firstValueFrom(this.eventsService.updatePO(this.store$.value.eventId, result));
        } else {
          await firstValueFrom(this.eventsService.savePONumbers(this.store$.value.eventId, result));
        }

        this.getPoNumbers();
        this.getData();
      });
  }

  addPONumberByEvent(event: EventModel, pagination: PageEvent): void {
    this.eventsService
      .getManufacturers(event.id)
      .pipe(take(1))
      .subscribe(manufacturers => {
        this.eventsService
          .getPoNumbers(event.id)
          .pipe(take(1))
          .subscribe(existingPONumbers => {
            const PONumbers: PONumberModel[] = [];

            manufacturers.forEach(m => {
              const poNumber: PONumberModel = existingPONumbers.find(po => po.manufacturer.id === m.id);

              if (poNumber) {
                PONumbers.push(poNumber);
              } else {
                const mockPONumber: PONumberModel = new PONumberModel();
                mockPONumber.manufacturer = m;
                PONumbers.push(mockPONumber);
              }
            });

            const dialogConfig: MatDialogConfig = { ...CreateSmallEntityConfig };

            dialogConfig.data = {
              eventId: event.id,
              poNumbers: PONumbers
            };
            this.dialog
              .open(PoNumberComponent, dialogConfig)
              .afterClosed()
              .pipe(take(1))
              .subscribe(async result => {
                this.store$.next({ ...this.store$.value, loading: false });

                if (!result) {
                  return;
                }

                if (existingPONumbers?.length && event.eventStatus === 'PAID') {
                  await firstValueFrom(this.eventsService.updatePO(event.id, result));
                } else {
                  await firstValueFrom(this.eventsService.savePONumbers(event.id, result));
                }

                this.managerSearchParamsService.getPageable(pagination);
              });
          });
      });
  }

  getPoNumbers(): void {
    this.eventsService
      .getPoNumbers(this.store$.value.eventId)
      .pipe(take(1))
      .subscribe(poNumbers => {
        this.store$.next({ ...this.store$.value, ...{ poNumbers } });
      });
  }

  getData(onlyEventData?: boolean): void {
    this.toggleLoading(true);
    this.eventsService
      .getEvent(this.store$.value.eventId, true)
      .pipe(take(1))
      .subscribe((data: EventModel) => {
        if (data) {
          this.store$.next({ ...this.store$.value, ...{ event: data } });
          this.store$.next({ ...this.store$.value, ...{ physicians: data.physicians } });
          this.store$.next({ ...this.store$.value, ...{ modifiers: data.modifiers?.map(modifier => ({ modifierName: modifier })) || [] } });
          const currentUser = UsersService.getUser();
          /** Quick transfer can be created if representative is non admin and
           * customPermissions.transferInventoryFromCustodianOnlyAllowed is enabled on non admin custom permissions.
           * initiateTransfersEnabled must be enabled in org settings.
           * Admin is able to create quick transfer for non admins, non admins can create quick transfer for themselves
           */
          const canManageEvent =
            currentUser.role === 'ADMIN'
              ? data?.representative?.customPermissions?.transferInventoryFromCustodianOnlyAllowed &&
                this.permissionService.isGranted('custom', 'initiateTransfersEnabled')
              : currentUser.id === data?.representative?.id && UsersService.isQuickTransfersEnabled$.value;

          this.store$.next({
            ...this.store$.value,
            ...{ isQuickTransfersEnabled: canManageEvent }
          });

          // FM role, can edit events where he is representative
          if (this.permissionService.isGranted('events', 'canEditSelfRepresentativeEventsOnly')) {
            const isAllowEdit = data.representative?.id === UsersService.getUser().id;
            this.store$.next({ ...this.store$.value, ...{ allowEdit: isAllowEdit } });
          }
          if (this.store$.value.event.isDevicesUsedProcessed === false) {
            this.refreshQuickTransferProcessingStatus();
          }

          if (!onlyEventData) {
            this.checkDisableEvent();

            if (data.eventType === 'CASE' || data.eventType === 'STOCKING_ORDER') {
              this.getEventQuote();
            }

            if (data.eventType === 'STOCKING_ORDER') {
              this.loadFacilityContacts();
            }

            if (data.reOpenedEvent || data.eventStatus === 'CANCELLED') {
              this.getEventActions(data.id);
            }

            if (data.eventType === 'CASE' || data.eventType === 'LOAN' || data.eventType === 'TRIAL' || data.eventType === 'OTHER') {
              this.getContainersOnReserve();
            }

            this.dataPassService.getSubordinators(data);
            this.loadAssignedDevices();
            this.getRequests();
          }

          this.toggleLoading(false);
        }
      });
  }

  async save(
    triggerCheckInventoryRequest: boolean = false,
    parameters?: { commissionPaid: TEventPaidStatus; invoicePaid: TEventPaidStatus; processedStatus: TSalesOrderStatus }
  ): Promise<void> {
    this.toggleLoading(true);

    if (this.store$.value.latestFacilityContactId) {
      const contact: ContactModel = new ContactModel();
      contact.id = this.store$.value.latestFacilityContactId;
      this.assignFacilityContact(contact);
    }

    const buildParams: IEventBuildParams = {
      selectedProducts: this.store$.value.productLines,
      selectedManufacturers: this.store$.value.manufacturers,
      required: this.store$.value.required,
      assignments: this.store$.value.assignments,
      userTimeZone: UsersService.userTimeZone,
      redirect: false,
      commissionPaid: parameters?.commissionPaid || this.store$.value.event.commissionPaid,
      invoicePaid: parameters?.invoicePaid || this.store$.value.event.invoicePaid,
      processedStatus: parameters?.processedStatus || this.store$.value.event.processedStatus,
      eventData: this.store$.value.event,
      modifiers: this.store$.value.modifiers
    };

    const procedure: ProcedureModel = this.store$.value.event.procedure;
    const newModifiers: string[] = this.getUniqueModifiers(procedure);

    // Сhecking for the existence of an event type (preventing loss of event data when switching tabs)
    const params: EventSaveRequestBuild = this.store$.value.required?.get('eventType').value
      ? new EventSaveRequestBuild(buildParams)
      : EventSaveRequestBuild.buildRequestFromEventData(buildParams);

    const saved = await firstValueFrom(this.eventsService.updateEvent(this.store$.value.eventId, params));

    if (saved) {
      if (triggerCheckInventoryRequest) {
        this.store$.next({ ...this.store$.value, ...{ createRequestFromRecipe: true } });
      }
      this.store$.value.required?.reset();
      this.store$.value.assignments?.reset();

      // When the user changed the route and the event has been saved via autosave, the function still try to get data for event
      if (!this.router.url.includes(this.store$.value.eventId)) {
        return;
      }

      this.getData();

      if (newModifiers.length) {
        this.updateProcedureModifiers(procedure, newModifiers);
      }

      if (this.store$.value.event.eventType !== 'STOCK_ADJUSTMENT') {
        this.loadManufacturers();
        this.getProductLines();
        if (this.store$.value.event.eventType === 'STOCKING_ORDER') {
          this.loadFacilityContacts();
        } else {
          this.loadContainers();
        }
      }
    }
    this.toggleLoading(false);
  }

  toggleLoading(loading: boolean): void {
    this.store$.next({ ...this.store$.value, ...{ loading } });
  }

  getUniqueModifiers(procedure: ProcedureModel): string[] {
    const newModifiers: string[] = [];

    this.store$.value.modifiers.forEach(m => {
      const index: number = (procedure.modifiers || []).indexOf(m.modifierName);

      if (index === -1) {
        newModifiers.push(m.modifierName);
      }
    });

    return newModifiers;
  }

  getProductLines(checkProductAvailability: boolean = false): void {
    this.eventsService
      .getProducts(this.store$.value.eventId)
      .pipe(take(1))
      .subscribe(productLines => {
        this.store$.next({ ...this.store$.value, ...{ productLines } });

        if (
          !productLines.length &&
          checkProductAvailability &&
          (this.store$.value.event.eventType === 'TRIAL' || this.store$.value.event.eventType === 'STOCKING_ORDER')
        ) {
          this.alertsService.showWarning('shared.alerts.errorMessages.lastProductLine');
        }
      });
  }

  getContainersOnReserve(): void {
    this.eventsService
      .getContainersOnReserve(this.store$.value.eventId)
      .pipe(take(1))
      .subscribe(containers => {
        this.store$.next({ ...this.store$.value, ...{ containersOnReserve: containers } });
      });
  }

  async assignProducts(id: string): Promise<void> {
    await firstValueFrom(this.eventsService.assignProducts(this.store$.value.eventId, [id]));
  }

  async assignFacility(params: { custodyId: string; facilityId: string; callback?: () => void }): Promise<void> {
    const assignFacility: boolean = await firstValueFrom(this.userAssignmentsService.assignFacility(params.custodyId, [params.facilityId]));
    if (assignFacility) {
      if (params.callback) {
        params.callback.apply(this);
      }
      this.alertsService.showSuccess('shared.alerts.successMessages.facilityAssignedToRepresentative', null, 10000);
    }
  }

  async assignPhysician(params: { custodyId: string; physicianId: string; callback?: () => void }): Promise<void> {
    const assignPhysician: boolean = await firstValueFrom(
      this.userAssignmentsService.assignPhysician(params.custodyId, [params.physicianId])
    );
    if (assignPhysician) {
      this.alertsService.showSuccess('shared.alerts.successMessages.physicianAssignedToRepresentative', null, 10000);
      if (params.callback) {
        params.callback.apply(this);
      }
    }
  }

  getImages(): void {
    this.eventsService
      .getImages(this.store$.value.eventId)
      .pipe(take(1))
      .subscribe(images => {
        this.store$.next({ ...this.store$.value, ...{ images } });
      });
  }

  getAttachments(): void {
    this.eventsService
      .getAttachments(this.store$.value.eventId)
      .pipe(take(1))
      .subscribe(attachments => {
        this.store$.next({ ...this.store$.value, ...{ attachments } });
      });
  }

  getNotes(): void {
    this.eventsService
      .getNotes(this.store$.value.eventId)
      .pipe(take(1))
      .subscribe(notes => {
        this.store$.next({ ...this.store$.value, ...{ notes } });
      });
  }

  assignFacilityContact(facilityContact: ContactModel): void {
    this.store$.next({ ...this.store$.value, latestFacilityContactId: '' });
    this.eventsService
      .assignFacilityContact(this.store$.value.eventId, [facilityContact.id])
      .pipe(take(1))
      .subscribe(() => {
        if (this.store$.value.eventId) {
          this.loadFacilityContacts();
        }
      });
  }

  markAllNoCharge(flag: boolean): void {
    const invIds: string[] = [];
    this.store$.value.assignedDevicesOriginal.forEach(inv => {
      if (inv.billable) {
        invIds.push(inv.id);
      }
    });
    this.store$.value.assignedPacksOriginal.forEach(inv => {
      if (inv.billable) {
        invIds.push(inv.id);
      }
    });
    if (!invIds.length) {
      return;
    }
    this.eventsService
      .noCharge(this.store$.value.eventId, invIds, flag)
      .pipe(take(1))
      .subscribe(() => {
        this.getData(true);
        this.loadAssignedDevices();
      });
  }

  setReadyForUse() {
    this.eventsService
      .readyForUse(this.store$.value.eventId, true)
      .pipe(take(1))
      .subscribe(() => this.getData());
  }

  getEventQuote(): void {
    this.toggleLoading(true);
    this.eventQuotesService
      .getQuote(this.store$.value.eventId)
      .pipe(
        take(1),
        finalize(() => this.toggleLoading(false))
      )
      .subscribe(data => {
        this.store$.next({
          ...this.store$.value,
          ...{ quote: data }
        });
      });
  }

  createQuote(): void {
    const dialogConfig: MatDialogConfig = { ...CreateMediumEntityConfig };

    dialogConfig.data = {
      event: this.store$.value.event,
      manufacturers: this.store$.value.manufacturers,
      products: this.store$.value.productLines
    };

    this.dialog
      .open(CreateQuoteComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe(id => {
        if (id) {
          this.getData();
        }
      });
  }

  createQuoteFromPreferenceCard(eventId: string, preferenceCardId: string, refreshData?: boolean): void {
    this.toggleLoading(true);
    this.preferenceCardService
      .getAssignments(preferenceCardId)
      .pipe(take(1))
      .subscribe(data => {
        const assignments: PreferenceCardAssignment[] = data.content.filter(d => d.catalog);
        const quoteCatalogs: { catalogId: string; quantity: number }[] = assignments.map(c => ({
          catalogId: c.catalog.id,
          quantity: c.quantity
        }));
        const params: EventQuoteParamsModel = {
          eventId,
          note: 'This quote has been automatically created based on the associated preference card.',
          catalogQuoteQuantities: quoteCatalogs
        };

        if (!assignments.length) {
          this.alertsService.showError('shared.alerts.errorMessages.noCatalogsInPreferenceCard', null, 5000);
          this.toggleLoading(false);
          return;
        }

        this.eventQuotesService
          .createQuote(params)
          .pipe(
            take(1),
            finalize(() => this.toggleLoading(false))
          )
          .subscribe(quoteId => {
            if (quoteId && refreshData) {
              this.getEventQuote();
            }
          });
      });
  }

  markAsPaid(id?: string, pagination?: PageEvent): void {
    const eventId: string = id || this.store$.value.eventId;
    const dialogRef = this.dialog.open(
      ConfirmComponent,
      ConfirmModalConfig({
        description: LanguageService.instant('shared.alerts.prompt.markAsPaid')
      })
    );

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(confirm => {
        if (confirm) {
          this.toggleLoading(true);
          this.eventsService
            .markPaid(eventId)
            .pipe(
              take(1),
              finalize(() => this.toggleLoading(false))
            )
            .subscribe(result => {
              if (result) {
                if (id) {
                  this.managerSearchParamsService.getPageable(pagination);
                } else {
                  this.getData(true);
                }
              }
            });
        }
      });
  }

  getEventTypePredictions(): Observable<TEventType> {
    return this.aiPredictionsService.getEventType();
  }

  uploadImages(params: { event: Event; file?: Blob }, showSuccessMessage: boolean = true): void {
    const { event, file } = params;
    const { files }: HTMLInputElement = event.target as HTMLInputElement;
    const uploadedFiles: File[] = file ? [file] : Array.prototype.slice.call(files);
    const allPromises: Promise<any>[] = [];

    if (!uploadedFiles.length) {
      return;
    }

    for (const image of uploadedFiles) {
      if (!image.type.includes('image/') || !isValidFile(image)) {
        this.alertsService.showError('shared.alerts.errorMessages.invalidImageFormat');
        continue;
      }
      const formData = new FormData();
      formData.append('image', image);

      const promise = new Promise((resolve, _reject) => {
        this.eventsService.uploadImage(this.store$.value.eventId, formData, showSuccessMessage).then(res => {
          if (res) {
            resolve(res);
          }
        });
      });
      allPromises.push(promise);
    }

    Promise.all(allPromises).then(
      () => {
        this.getImages();
        if (allPromises.length && showSuccessMessage) {
          this.alertsService.showSuccess('shared.alerts.successMessages.imageWasUploaded');
        }
      },
      err => {
        this.alertsService.showError(null, err.message);
      }
    );
  }

  uploadAttachments(params: { event: Event; file: File; type: string }, showSuccessMessage: boolean = true): void {
    const { event, file } = params;
    const files = event ? (event.target as HTMLInputElement).files : null;
    const uploadedFiles = file ? [file] : Array.prototype.slice.call(files);
    const allPromises: Promise<any>[] = [];

    if (!uploadedFiles.length) {
      return;
    }

    for (const attachment of uploadedFiles) {
      const formData = new FormData();
      formData.append('attachment', attachment);
      allPromises.push(this.eventsService.uploadAttachments(this.store$.value.eventId, formData, showSuccessMessage));
    }

    Promise.all(allPromises).then(() => {
      if (showSuccessMessage) {
        this.alertsService.showSuccess('shared.alerts.successMessages.success');
      }
      this.getAttachments();
    });
  }

  applyFilter(): void {
    const val = this.store$.value.searchValue.toLowerCase();
    // Search devices
    const devices = [...this.store$.value.assignedDevicesOriginal];
    const filterDevices = (invItems: InventoryModel[]): InventoryModel[] => {
      return invItems.filter(device => {
        const lot = device.lotNumber || '';
        const serial = device.serialNumber || '';
        const name = device.name?.toLowerCase();
        const ref = device.catalog.referenceNumber.toLowerCase();
        const status = device.inventoryStatus.toLowerCase();
        const reusable = device.reusable ? 'yes' : 'no';
        const tags = device.tags || [];

        return (
          name.includes(val) ||
          ref.includes(val) ||
          lot.toLowerCase().includes(val) ||
          serial.toLowerCase().includes(val) ||
          status.includes(val) ||
          reusable.includes(val) ||
          tags.some(tag => tag.toLowerCase().includes(val))
        );
      });
    };
    const assignedDevicesSearched = filterDevices(devices);
    this.store$.next({ ...this.store$.value, ...{ assignedDevicesSearched } });

    // Search Packs
    const packs = [...this.store$.value.assignedPacksOriginal];
    const assignedPacksSearched = filterDevices(packs);
    this.store$.next({ ...this.store$.value, ...{ assignedPacksSearched } });
  }

  changeDutSort(): void {
    const dutSort = this.store$.value.dutSort === 'newestFirst' ? 'oldestFirst' : 'newestFirst';
    this.store$.next({ ...this.store$.value, ...{ assignedDevicesGrouped: this.store$.value.assignedDevicesGrouped.reverse() } });
    this.store$.value.assignedDevicesGrouped.forEach(d =>
      d.devices.sort((a, b) =>
        dutSort === 'newestFirst'
          ? new Date(a.modifiedDatetime).getTime() - new Date(b.modifiedDatetime).getTime()
          : new Date(b.modifiedDatetime).getTime() - new Date(a.modifiedDatetime).getTime()
      )
    );

    this.store$.next({ ...this.store$.value, ...{ dutSort } });

    LocalStorage.setItem('dutSort', dutSort);
  }

  changeDutPacksSort(): void {
    const dutPacksSort = this.store$.value.dutPacksSort === 'newestFirst' ? 'oldestFirst' : 'newestFirst';
    // this.store$.next({ ...this.store$.value, ...{ assignedPacksOriginal: this.store$.value.assignedPacksOriginal.reverse() } });
    this.store$.value.assignedPacksOriginal.sort((a, b) =>
      dutPacksSort === 'newestFirst'
        ? new Date(a.modifiedDatetime).getTime() - new Date(b.modifiedDatetime).getTime()
        : new Date(b.modifiedDatetime).getTime() - new Date(a.modifiedDatetime).getTime()
    );
    this.store$.next({ ...this.store$.value, ...{ dutPacksSort } });

    LocalStorage.setItem('dutPacksSort', dutPacksSort);
  }

  confimationAfterCreationRequest(id: string): void {
    if (id) {
      this.getRequests();
      const confirmModalConfig = ConfirmModalConfig({
        title: LanguageService.instant('confirmation.requestSubmittedSuccessfully'),
        description: LanguageService.instant('confirmation.requestSubmittedSuccessfullyDescription'),
        acceptButtonText: LanguageService.instant('confirmation.viewRequestDetails'),
        declineButtonText: LanguageService.instant('confirmation.stayOnEventPage'),
        showCloseButton: true
      });
      confirmModalConfig.width = '450px';
      const confirmRef = this.dialog.open(ConfirmComponent, confirmModalConfig);
      confirmRef
        .afterClosed()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.router.navigate([`/movement/requests/edit/${id}`]);
          }
        });
    }
  }

  getRequests(): void {
    const params: IFilter = {
      eventId: this.store$.value.eventId,
      selectedMatSortHeaderActive: 'createdDatetime',
      selectedMatSortHeaderDirection: 'desc'
    };

    this.requestsService
      .getPageable(new RequestPageableSearchParams(params))
      .pipe(
        take(1),
        map(data => data?.content || [])
      )
      .subscribe(items => this.store$.next({ ...this.store$.value, ...{ inventoryRequests: items } }));
  }

  refreshQuickTransferProcessingStatus(): void {
    this.store$.next({ ...this.store$.value, ...{ isAutoMarkedAsUsedProcessing: true } });
    this.eventsService
      .isDevicesUsedProcessedAfterQuickTransferCreation(this.store$.value.event.id)
      .pipe(take(1))
      .subscribe(result => {
        if (result === false) {
          setTimeout(() => this.refreshQuickTransferProcessingStatus(), 3000);
        } else if (result) {
          this.store$.next({ ...this.store$.value, ...{ isAutoMarkedAsUsedProcessing: false } });
          this.loadAssignedDevices();
        }
      });
  }

  private getEventActions(id: string): void {
    this.eventsService
      .getActions(id)
      .pipe(take(1))
      .subscribe(data => {
        this.store$.next({
          ...this.store$.value,
          ...{ actions: data }
        });
      });
  }

  private checkValidation(): void {
    setTimeout(() => {
      const eventType: TEventType = this.store$.value.event.eventType;
      const valid: boolean =
        this.store$.value.formsValid &&
        (eventType === 'STOCK_ADJUSTMENT' ? true : this.store$.value.manufacturers.length > 0) &&
        (eventType === 'TRIAL' || eventType === 'STOCKING_ORDER' ? Boolean(this.store$.value.productLines.length) : true) &&
        (eventType === 'STOCKING_ORDER' ? Boolean(this.store$.value.facilityContacts.length) : true);

      this.store$.next({ ...this.store$.value, ...{ valid } });
    }, 16);
  }

  /** Set flag on event page if any device price has been edited by non admin */
  private checkDevicePriceChanged(devices: InventoryModel[] = []): void {
    const ind = devices.findIndex(d => Boolean(d.priceChangedOn));
    this.store$.next({ ...this.store$.value, devicesPriceChanged: ind > -1 });
  }

  /** Check duplicates for reopened events */
  private checkDuplicates(devices: InventoryModel[] = []): void {
    if (!devices.length || !this.store$.value.event.reOpenedEvent) {
      return;
    }
    const ids: Record<string, true> = {};
    const dutInventoryDuplicates: InventoryModel[] = [];

    devices.forEach(val => {
      if (ids[val.id]) {
        // we have already found this same id
        dutInventoryDuplicates.push(val);
      } else {
        ids[val.id] = true;
      }
    });
    this.store$.next({ ...this.store$.value, dutInventoryDuplicates: RemoveDuplicateArrayById(dutInventoryDuplicates) });
  }

  private checkDisableEvent(): void {
    const event = this.store$.value.event;
    const canEditEvent = this.permissionService.isGranted('events', 'canEditEvent');
    const allowEdit =
      event.eventStatus === 'OPEN' &&
      this.store$.value.isDistributorCanEdit &&
      !event.representative?.isOffline &&
      canEditEvent &&
      this.permissionService.isGranted('userLevel', 'manageEventsAllowed') &&
      (event.eventType === 'STOCK_ADJUSTMENT'
        ? this.permissionService.isGranted('userLevel', 'invDeactivationAndStockEventCreationAllowed')
        : true) &&
      (this.permissionService.isGranted('events', 'canEditSelfRepresentativeEventsOnly')
        ? event.representative?.id === UsersService.getUser().id
        : true);

    const allowEditDevices =
      event.eventStatus === 'OPEN' &&
      this.store$.value.isDistributorCanEdit &&
      !event.representative?.isOffline &&
      this.permissionService.isGranted('userLevel', 'manageEventsAllowed') &&
      (event.eventType === 'STOCK_ADJUSTMENT'
        ? this.permissionService.isGranted('userLevel', 'invDeactivationAndStockEventCreationAllowed')
        : true) &&
      (this.permissionService.isGranted('events', 'canEditSelfRepresentativeEventsOnly')
        ? event.representative?.id === UsersService.getUser().id
        : true);

    this.store$.next({ ...this.store$.value, ...{ allowEdit } });
    this.store$.next({ ...this.store$.value, ...{ allowEditDevices } });
  }

  private sumDeviceStatuses(): void {
    const statusesCount = new StatusesCount();
    const allDevices = [...this.store$.value.assignedDevicesOriginal, ...this.store$.value.assignedPacksOriginal];
    allDevices.forEach(device => (statusesCount[device.inventoryStatus] += 1));
    this.store$.next({ ...this.store$.value, ...{ statusesCount: statusesCount } });
  }

  private checkNoCharge(): void {
    const allDevices = [...this.store$.value.assignedDevicesOriginal, ...this.store$.value.assignedPacksOriginal];
    const billableDeviceIndex: number = allDevices.findIndex(d => d.billable === true);
    this.store$.next({
      ...this.store$.value,
      ...{ existDeviceToMarkNoCharge: billableDeviceIndex !== -1 }
    });

    const isNoCharge: boolean = allDevices.every(inv => inv.noCharge === true || inv.billable === false);
    this.store$.next({ ...this.store$.value, ...{ isChargeAll: isNoCharge } });
  }

  private adaptedDataForView(items: InventoryModel[] = []): InventoryModel[] {
    const result = new Map<string, any>();
    items.forEach(item => {
      const price = item.billable === false ? 0 : parseFloat(item.eventPrice?.toFixed(2));
      const itemLotNum = item.lotNumber ? item.lotNumber : '-';
      const itemSerialNum = item.serialNumber ? item.serialNumber : '-';
      const key = item.catalog.referenceNumber + itemLotNum + itemSerialNum;
      const obj = result.get(key) || {
        catalogNumber: item.catalog.referenceNumber,
        lotNumber: itemLotNum,
        serialNumber: itemSerialNum,
        count: 0,
        devices: [],
        dutAmount: 0,
        isFacilityPrice: item.isFacilityPrice,
        matExpansionPanelUniqueId: key
      };
      obj.devices.push(item);
      obj.count++;
      obj.dutAmount += price;
      result.set(key, obj);
    });
    return Array.from(result.values());
  }
}
