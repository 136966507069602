<div
  *ngIf="showBurgerMenu"
  data-layout="column"
  [@inOutHorizontalAnimation]
  class="mobileMenuContainer"
  [ngClass]="{ DARK: (colorTheme$ | async) !== 'DARK_BLUE' }"
  [style.height]="(innerHeight$ | async) + 'px'"
  data-layout-gap="12px"
>
  <div data-layout="row" data-layout-align="space-between start" class="headerContainer">
    <div class="closeButtonContainer" data-layout-align="center center" (click)="$event.stopPropagation(); menuTogglerEmitter.emit(false)">
      <app-icon [icon]="'close'" [size]="24"></app-icon>
    </div>
    <a
      data-layout="row"
      data-layout-align="start center"
      routerLink="/dashboard"
      class="imageContainer"
      (click)="$event.stopPropagation(); menuTogglerEmitter.emit(false)"
    >
      <img [src]="getLabelURL" alt="ConnectSx Label" />
    </a>
  </div>

  <div
    *ngIf="(isOffline$ | async) === false; else offlineNavigation"
    class="menuContainer"
    data-layout="column"
    data-layout-align="start stretch"
    data-layout-gap="4px"
  >
    <a
      (click)="$event.stopPropagation(); menuTogglerEmitter.emit(false)"
      data-layout="row"
      data-layout-gap="8px"
      data-layout-align="start center"
      class="navOption"
      routerLink="/dashboard"
      routerLinkActive="activeNavOption"
      data-cy="dashboardNavigation"
    >
      <app-icon [size]="24" [type]="isActive(['/dashboard']) ? 'filled' : 'outline'" [icon]="'category'"></app-icon>
      <span>{{ 'shared.tooltips.navigation.dashboard' | language }}</span></a
    >

    <a
      (click)="$event.stopPropagation(); menuTogglerEmitter.emit(false)"
      data-layout="row"
      data-layout-gap="8px"
      data-layout-align="start center"
      class="navOption"
      routerLink="/events/"
      routerLinkActive="activeNavOption"
      data-cy="eventsNavigation"
    >
      <app-icon [size]="24" [type]="isActive(['/events/']) ? 'filled' : 'outline'" [icon]="'calendar'"></app-icon>
      <span>{{ 'shared.tooltips.navigation.events' | language }}</span></a
    >

    <a
      (click)="showDirectoryRoutes = false; showMovementRoutes = false; showInventoryRoutes = !showInventoryRoutes"
      data-layout="row"
      data-layout-gap="8px"
      data-layout-align="start center"
      class="navOption"
      data-cy="inventoryNavigation"
      [ngClass]="{
        activeNavOption: isActive(['/inventory']) && !isActive(['/statistics']),
        inactiveNavOption: !isActive(['/inventory']) && showInventoryRoutes
      }"
    >
      <app-icon [size]="24" [type]="isActive(['/inventory']) ? 'filled' : 'outline'" [icon]="'bag_2'"></app-icon>
      <div data-flex="100" data-layout="row" data-layout-align="space-between center">
        <span>{{ 'shared.tooltips.navigation.inventory' | language }}</span>
        <app-icon [size]="20" [type]="'outline'" [icon]="showInventoryRoutes ? 'arrow_down_filled' : 'arrow_right_filled'"></app-icon>
      </div>
    </a>

    <ng-container *ngIf="showInventoryRoutes">
      <div class="subnavContainer" data-layout="column" [@inOutAnimation]>
        <ng-container *ngFor="let item of inventoryRoutes; trackBy: trackBy">
          <a
            data-layout="row"
            data-layout-align="start center"
            *appHasPermission="{ scope: item.permissionScope, permissionName: item.permissionName }"
            [routerLink]="item.link"
            class="navOption subNavOption"
            routerLinkActive="activeNavOption"
            [attr.data-cy]="item.dataCy"
            [ngClass]="{ positionRelative: item.permissionName === 'readAudits' }"
            (click)="$event.stopPropagation(); menuTogglerEmitter.emit(false)"
          >
            @if (item.permissionName === 'readAudits' && showAuditState) {
              <span class="badge"></span>
            }
            {{ item.name | language }}</a
          >
        </ng-container>
      </div>
    </ng-container>

    <a
      (click)="showDirectoryRoutes = false; showInventoryRoutes = false; showMovementRoutes = !showMovementRoutes"
      data-layout="row"
      data-layout-gap="8px"
      data-layout-align="start center"
      class="navOption"
      data-cy="movementNavigation"
      [ngClass]="{ activeNavOption: isActive(['/movement']), inactiveNavOption: !isActive(['/movement']) && showMovementRoutes }"
    >
      <app-icon class="rotate-right" [size]="24" [type]="'outline'" [icon]="'swap'"></app-icon>
      <div data-flex="100" data-layout="row" data-layout-align="space-between center">
        <span>{{ 'shared.tooltips.navigation.movements' | language }}</span>
        <app-icon [size]="20" [type]="'outline'" [icon]="showMovementRoutes ? 'arrow_down_filled' : 'arrow_right_filled'"></app-icon>
      </div>
    </a>

    <ng-container *ngIf="showMovementRoutes">
      <div class="subnavContainer" data-layout="column" [@inOutAnimation]>
        <ng-container *ngFor="let item of movementRoutes; trackBy: trackBy">
          <a
            data-layout="row"
            data-layout-align="start center"
            *appHasPermission="{ scope: item.permissionScope, permissionName: item.permissionName }"
            [routerLink]="item.link"
            class="navOption subNavOption"
            routerLinkActive="activeNavOption"
            [attr.data-cy]="item.dataCy"
            (click)="$event.stopPropagation(); menuTogglerEmitter.emit(false)"
            >{{ item.name | language }}</a
          >
        </ng-container>
      </div>
    </ng-container>

    <a
      (click)="
        $event.stopPropagation(); showInventoryRoutes = false; showMovementRoutes = false; showDirectoryRoutes = !showDirectoryRoutes
      "
      data-layout="row"
      data-layout-gap="8px"
      data-layout-align="start center"
      class="navOption"
      data-cy="directoryNavigation"
      [ngClass]="{ activeNavOption: isActive(['/directory']), inactiveNavOption: !isActive(['/directory']) && showDirectoryRoutes }"
    >
      <app-icon [size]="24" [type]="isActive(['/directory']) ? 'filled' : 'outline'" [icon]="'document'"></app-icon>
      <div data-flex="100" data-layout="row" data-layout-align="space-between center">
        <span>{{ 'shared.tooltips.navigation.directory' | language }}</span>
        <app-icon [size]="20" [type]="'outline'" [icon]="showDirectoryRoutes ? 'arrow_down_filled' : 'arrow_right_filled'"></app-icon></div
    ></a>

    <ng-container *ngIf="showDirectoryRoutes">
      <div class="subnavContainer" data-layout="column" [@inOutAnimation]>
        <ng-container *ngFor="let item of directoryRoutes; trackBy: trackBy">
          <a
            data-layout="row"
            data-layout-align="start center"
            (click)="$event.stopPropagation(); menuTogglerEmitter.emit(false)"
            *appHasPermission="{ scope: item.permissionScope, permissionName: item.permissionName }"
            [routerLink]="item.link"
            class="navOption subNavOption"
            routerLinkActive="activeNavOption"
            [attr.data-cy]="item.dataCy"
            >{{ item.name | language }}</a
          >
        </ng-container>
      </div>
    </ng-container>
    <a
      *appHasPermission="{ scope: 'report', permissionName: 'readReport' }"
      data-layout="row"
      data-layout-gap="8px"
      data-layout-align="start center"
      class="navOption"
      [routerLink]="'/statistics'"
      [ngClass]="{ activeNavOption: isActive(['/statistics']) }"
      data-cy="reportsNavigation"
      (click)="$event.stopPropagation(); menuTogglerEmitter.emit(false)"
    >
      <app-icon [size]="24" [type]="isActive(['/statistics']) ? 'filled' : 'outline'" [icon]="'download'"></app-icon>
      <span>{{ 'shared.tooltips.navigation.reports' | language }}</span></a
    >
    <a
      data-layout="row"
      data-layout-gap="8px"
      data-layout-align="start center"
      class="navOption"
      *appHasPermission="{ scope: 'user', permissionName: 'canReadUsers' }"
      routerLink="/users"
      routerLinkActive="activeNavOption"
      data-cy="usersNavigation"
      (click)="$event.stopPropagation(); menuTogglerEmitter.emit(false)"
    >
      <app-icon [size]="24" [type]="isActive(['/users']) ? 'filled' : 'outline'" [icon]="'users'"></app-icon>
      <span>{{ 'shared.tooltips.navigation.users' | language }}</span></a
    >

    <a
      *appHasPermission="{ scope: 'settings', permissionName: 'readIntegrations' }"
      data-cy="menuItemSettings"
      data-layout="row"
      data-layout-gap="8px"
      data-layout-align="start center"
      class="navOption"
      [ngClass]="{ activeNavOption: isActive(['/integrations']) }"
      routerLink="/integrations"
      (click)="$event.stopPropagation(); menuTogglerEmitter.emit(false)"
    >
      <app-icon [size]="18" [type]="isActive(['/integrations']) ? 'filled' : 'outline'" [icon]="'puzzle'"></app-icon>
      <span>{{ 'shared.tooltips.navigation.integrations' | language }}</span></a
    >

    <a
      *appHasPermission="{ scope: 'settings', permissionName: 'readSettings' }"
      data-cy="menuItemSettings"
      data-layout="row"
      data-layout-gap="8px"
      data-layout-align="start center"
      class="navOption"
      [ngClass]="{ activeNavOption: isActive(['/settings']) }"
      routerLink="/settings/user-profile"
      (click)="$event.stopPropagation(); menuTogglerEmitter.emit(false)"
    >
      <app-icon [size]="24" [type]="isActive(['/settings']) ? 'filled' : 'outline'" [icon]="'setting'"></app-icon>
      <span>{{ 'shared.tooltips.navigation.settings' | language }}</span></a
    >

    <a
      id="searchWidgetTrigger"
      data-layout="row"
      data-layout-gap="8px"
      data-layout-align="start center"
      class="navOption"
      (click)="menuTogglerEmitter.emit(false); openAIFeaturesListModalEmitter.emit()"
    >
      <app-icon [size]="24" [type]="'outline'" [icon]="'sparkle'"></app-icon>
      <span>{{ 'shared.tooltips.navigation.aISearch' | language }}</span>
    </a>

    <a
      *appHasPermission="{ scope: 'notifications', permissionName: 'readNotifications' }"
      data-layout="row"
      data-layout-gap="16px"
      data-layout-align="start center"
      class="navOption"
      (click)="$event.stopPropagation(); menuTogglerEmitter.emit(false); openMessagingWindow()"
    >
      <span data-layout="row" data-layout-align="start center" data-layout-gap="8px">
        <app-icon [size]="24" [type]="'outline'" [icon]="'message'"></app-icon>
        <span>{{ 'shared.labels.messaging' | language }}</span>
      </span>

      <span *ngIf="totalMessagingNotifications" class="messagingQty">{{ totalMessagingNotifications }}</span>
    </a>

    <a
      *ngIf="platform !== 'web'"
      data-layout="row"
      data-layout-gap="8px"
      data-layout-align="start center"
      class="navOption"
      [ngClass]="{ activeNavOption: isActive(['/app-info']) }"
      routerLink="/app-info"
      (click)="$event.stopPropagation(); menuTogglerEmitter.emit(false)"
    >
      <app-icon [size]="24" [type]="isActive(['/app-info']) ? 'filled' : 'outline'" [icon]="'info_square'"></app-icon>
      <span>{{ 'shared.tooltips.navigation.appInfo' | language }}</span></a
    >

    <div
      *ngIf="!isProd && platform !== 'web'"
      data-cy="menuItemSettings"
      data-layout="row"
      data-layout-gap="8px"
      data-layout-align="start center"
      class="navOption overFlowScroll"
    >
      <b>QA APNS token: </b>
      <p>{{ pushNotificationsService.apnsToken$ | async }}</p>
    </div>

    <!--Intercom chat widget has been disabled, commented widget button in navigation, maybe in the future it will be enabled again-->
    <!--    <a-->
    <!--      data-layout="row"-->
    <!--      data-layout-align="start center"-->
    <!--      data-layout-gap="8px"-->
    <!--      (click)="$event.stopPropagation(); menuTogglerEmitter.emit(false); initWidget()"-->
    <!--      class="navOption"-->
    <!--    >-->
    <!--      <app-icon [size]="24" [icon]="'help'" [type]="'outline'"></app-icon>-->
    <!--      <span>{{ 'shared.tooltips.navigation.help' | language }}</span>-->
    <!--    </a>-->
    <a
      data-cy="menuItemLogout"
      data-layout="row"
      data-layout-gap="8px"
      data-layout-align="start center"
      class="navOption"
      routerLink="/login"
      (click)="menuTogglerEmitter.emit(false); logoutEmitter.emit()"
    >
      <app-icon [size]="24" [type]="'outline'" [icon]="'logout'"></app-icon>
      <span>{{ 'shared.tooltips.navigation.logout' | language }}</span></a
    >
  </div>

  <a
    *ngIf="isCustodianOnly"
    data-layout="row"
    data-layout-gap="8px"
    data-layout-align="start center"
    class="navOption activeNavOption activeNavOptionOrange"
  >
    {{ 'users.custodianOnly' | language }}</a
  >
  <a
    *ngIf="isDemoModeOnly"
    data-layout="row"
    data-layout-gap="8px"
    data-layout-align="start center"
    class="navOption activeNavOption activeNavOptionOrange"
  >
    {{ 'shared.labels.demoMode' | language }}</a
  >

  <ng-container *ngIf="platform !== 'web'">
    <div *appHasPermission="{ scope: 'config', permissionName: 'offlineMode' }" class="bottomButtonContainer">
      <app-button-kit
        *ngIf="(isOffline$ | async) === false"
        data-flex="100px"
        data-flex-lt-lg="344px"
        data-flex-xs="100"
        [dataCy]="'offlineButton'"
        [disabled]="false"
        [type]="'primary'"
        [color]="'primary'"
        [textColor]="'white'"
        [size]="'large'"
        [title]="'offline.goOffline' | language"
        (clickEmitter)="enableOffline()"
      ></app-button-kit>
    </div>
  </ng-container>

  <div class="footerContainer" data-layout="column" data-layout-gap="4px">
    <div class="profileContainer" data-layout-align="space-between center">
      <div data-layout="row" data-layout-gap="8px" data-layout-align="start center">
        <div class="profile" data-layout-align="center center">
          <span>{{ nameInitials }}</span>
        </div>
        <div data-layout="column" data-layout-gap="4px">
          <div class="profileName">{{ userName }}</div>
          <div
            *ngIf="(isOffline$ | async) === false"
            data-layout="row nowrap"
            data-layout-align="start center"
            class="profileOrg"
            (click)="$event.stopPropagation(); organizations$.value.length > 1 ? openBottomSheet() : null"
          >
            <span class="profileOrgName">{{ currentOrganization.company.name }}</span>
            <app-icon
              *ngIf="(organizations$ | async).length > 1 && (isOffline$ | async) === false"
              [size]="16"
              [type]="'outline'"
              [icon]="'arrow_right_filled'"
              [color]="'overflow'"
            ></app-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #offlineNavigation>
  <div class="full-height" data-layout="column" data-layout-align="start space-between">
    <div class="menuContainer" data-layout="column" data-layout-align="start stretch" data-layout-gap="4px">
      <a
        (click)="$event.stopPropagation(); menuTogglerEmitter.emit(false)"
        data-layout="row"
        data-layout-gap="8px"
        data-layout-align="start center"
        class="navOption"
        routerLink="/events/"
        routerLinkActive="activeNavOption"
      >
        <app-icon [size]="24" [type]="isActive(['/events/']) ? 'filled' : 'outline'" [icon]="'calendar'"></app-icon>
        <span>{{ 'shared.tooltips.navigation.events' | language }}</span></a
      >
      <a
        (click)="$event.stopPropagation(); menuTogglerEmitter.emit(false)"
        data-layout="row"
        data-layout-gap="8px"
        data-layout-align="start center"
        class="navOption"
        routerLink="/inventory/inventories/"
        routerLinkActive="activeNavOption"
      >
        <app-icon [size]="24" [type]="isActive(['/inventory']) ? 'filled' : 'outline'" [icon]="'bag_2'"></app-icon>
        <span>{{ 'shared.tooltips.navigation.inventory' | language }}</span>
      </a>
    </div>
    <div class="bottomButtonContainer">
      <app-button-kit
        data-flex="100px"
        data-flex-lt-lg="344px"
        data-flex-xs="100"
        [disabled]="(isNetworkAvailable$ | async) === false"
        [type]="'primary'"
        [color]="'success'"
        [textColor]="'white'"
        [size]="'large'"
        [title]="'offline.backOnline' | language"
        (clickEmitter)="disableOffline()"
      ></app-button-kit>
    </div>
  </div>
</ng-template>
