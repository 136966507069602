.mat-mdc-select-panel.matSelectDisableOptionCentering.matSelectScrollingPanel {
  max-height: 250px;
  overflow-y: auto;
  min-height: 0;
}
.inputKitContainer.filterView .mat-mdc-text-field-wrapper .mdc-floating-label,
.inputKitContainer.filterView
  .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
  .mdc-floating-label {
  color: var(--text-secondary);
}
