import {
  ContactModel,
  FacilityModel,
  InventoryRequestModel,
  ManufacturerModel,
  PhysicianModel,
  PreferenceCardModel,
  ProcedureModel,
  UserModel
} from '@shared/models';
import { PONumberModel } from '@shared/models/features/events/po-number.model';
import { ImageModel } from '@shared/models/shared/image.model';
import { TEventPaidStatus, TEventStatus, TEventType, TSalesOrderStatus, TState } from '@shared/type/index.type';
import { ModifiedDatetime, CreatedDatetime } from '@shared/models/shared/shared-kernel';
import { EEventCreditStatus } from '@shared/enum';

export class EventModel {
  authProviderSignature: string;
  authProviderSignatureUrl: string;
  billableTotal: number;
  commissionPaid: TEventPaidStatus;
  createdBy: UserModel;
  createdDatetime: CreatedDatetime;
  datetime: string;
  description: string;
  distributorGroup: string[];
  eventCreditStatus: keyof typeof EEventCreditStatus;
  eventFacilityContacts: { facilityContact: ContactModel }[];
  eventStatus: TEventStatus;
  eventType: TEventType;
  expanded?: boolean; // Front-end property
  facility: FacilityModel;
  facilityStockEvent: boolean;
  healthRecordId: string;
  id: string;
  images?: ImageModel[];
  inventoryRequest: InventoryRequestModel;
  invoiceNumber: number | null;
  invoicePaid: TEventPaidStatus;
  isClosedOffline?: boolean; // Front-end property
  lastInvoiceSentDatetime: Date;
  lastSalesOrderSentDatetime: Date;
  makeReadyForSaleBy: UserModel;
  makingReadyForSaleDateTime: ModifiedDatetime;
  manufacturers?: ManufacturerModel[];
  modifiedBy: UserModel;
  modifiedDatetime: ModifiedDatetime;
  modifiers: string[];
  name: string;
  number?: number;
  offset: number;
  physicians: PhysicianModel[];
  poHasBeenRemoved: boolean;
  poNumbers?: PONumberModel[];
  preferenceCard: PreferenceCardModel;
  principalInvestigator: string;
  procedure: ProcedureModel;
  processedStatus: TSalesOrderStatus;
  protocolNumber: string;
  reOpenedEvent: boolean;
  readyForUse: boolean;
  representative: UserModel;
  representativeSignature: string;
  representativeSignatureUrl: string;
  shippingCharges: number;
  state: TState;
  stickerUrl: string;
  timeZone: string;
  total?: number;
  /** true - transfer created & devices added to event
      false - processing
      null - no quick transfer were created
   */
  isDevicesUsedProcessed: boolean;
}
