.mat-mdc-checkbox.checkboxKit {
  .mdc-checkbox__ripple,
  .mat-ripple {
    display: none;
  }
  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
  .mdc-checkbox {
    width: 16px;
    height: 16px;
    flex: 16px;
    padding: 0;
    .mat-mdc-checkbox-touch-target {
      all: unset;
    }
    .mdc-checkbox__background {
      border-radius: 5px;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      border: 1px solid var(--text-caption);
      background-color: transparent;
    }
    .mdc-checkbox__native-control {
      width: inherit;
      height: inherit;
    }
  }
  .mdc-label {
    font-weight: 400;
    padding-left: 6px;
    color: var(--text-secondary);
    font-size: 11px;
    line-height: 16px;
    white-space: nowrap;
  }
  &.checkboxWithoutLabel {
    .mdc-label {
      display: none;
    }
  }
  &.wrapTitle {
    .mdc-label {
      white-space: normal;
    }
  }
}
