import { PermissionModel } from '@shared/models';
import { MergeModels } from '../../merge-models';
import { ECompanyTypes } from '@shared/enum';

export class DistributorFacilityManagerRole {
  model: PermissionModel = {
    config: {
      companyType: ECompanyTypes.DISTRIBUTOR,
      roleName: 'FACILITY_MANAGER',
      validRole: true,
      permissionsWasSet: true,
      offlineMode: true
    },
    common: {
      visitDetailPageAssignedEntity: true,
      addComment: true
    },
    forbiddenRoutes: [
      '/users',
      '/users/list',
      '/integrations',
      '/directory/regions',
      '/directory/price-lists',
      '/organization-tree',
      '/statistics'
    ],
    dashboard: {
      readSalesByRepresentativeWidget: true,
      readSalesTargetWidget: true
    },
    events: {
      canCloseEvent: true,
      canSignEvent: true,
      canAddPONumber: true,
      canChangeEventStatus: true,
      canScheduleEvent: true,
      canEditEvent: true,
      canCreateEventForSelfOnly: true,
      toggleCalendarView: true,
      filterEvents: true,
      createEvent: true,
      seeImages: true,
      editPriceAdjustments: true,
      readTotalValue: true,
      signEventUsingCanvas: true,
      deviceUsedBulkActions: true,
      submitForBillingRequestReplenishment: true,
      useAdditionalFiltersOnMUI: true,
      copyEvent: true,
      editPONUmber: true,
      canEditSelfRepresentativeEventsOnly: true
    },
    settings: {
      editSelfIndividualAccountInfo: true
    },
    report: {},
    inventory: {
      scanViaUDIdentify: true,
      createInventoryRequestWithManufacturerWithoutEmail: true,
      createInventory: true,
      canCreateInventoryForSelfOnly: true,
      createInventoryTransfer: true,
      canEditInventoryAdditionalInfo: true,
      canEditInventoryPriceInfo: true,
      canEditInventoryPriceInfoForSelfCreatedOnly: true,
      createInventoryTransferFromSelfOnly: true,
      acceptSelfTransferOnly: true,
      canReadInventoryImport: true,
      canMarkInventoryAsUsedInEvent: true,
      canEditPriceOfInventoryInDUT: true,
      canAdd_IFY_ToProductLine: true,
      canInitiateInventoryRequest: true,
      canCreateInventoryRequestToOrgOnly: true,
      filterProductLinesByAssignedToCurrentUser: true,
      readInventoryValue: true,
      filterInventories: true,
      readCatalogDetail: true,
      readProductLineDetail: true,
      readImportDetail: true,
      readEventDetail: true,
      markDeviceInEvent: true,
      createBOM: true
    },
    movements: {
      createLocation: true,
      createRequestRecipe: true
    },
    directory: {
      createPhysician: true,
      createProcedure: true,
      editPhysician: true,
      editProcedure: true,
      canAddNotesToFacility: true,
      canAddNotesToPhysician: true,
      canAddNotesToProcedure: true,
      canEditSelfOnlyPhysician: true,
      canEditSelfOnlyProcedure: true,
      readFacilityDetail: true,
      readPhysicianDetail: true,
      readProcedureDetail: true,
      readManufacturerDetail: true
    }
  };

  constructor() {
    this.model = MergeModels(this.model);
  }
}
