.datepickerKitContainer {
  background-color: var(--surface);
  border-radius: 12px;
  padding: 5px 6px;
  max-height: 30px;
  overflow: hidden;
  border: 0.5px solid transparent;
  position: relative;
  &.filterView {
    background-color: var(--overlay-dark);
    height: 48px;
    max-height: 48px;
    padding: 0 12px;
    .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
      font-size: 14px;
    }
    .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
      padding-bottom: 0;
      padding-top: 22px;
    }
    .datepickerToggle {
      top: 10px;
    }
    .mat-mdc-floating-label.mdc-floating-label {
      font-size: 14px;
      color: var(--text-secondary);
      top: 24px;
    }
  }
  .iconsContainer {
    margin-top: 14px;
    margin-right: -4px;
  }
  &.withBackground {
    background-color: var(--overlay-dark);
  }
  .datepickerToggle {
    font-size: 14px;
    position: absolute;
    color: var(--text-caption);
    right: 8px;
    top: 0;
    button {
      width: 24px;
      height: 24px;
      padding: 0;
    }
  }
  &.invalidInput {
    border: 1px solid var(--red-primary);
  }
  &.disabledInput {
    border: 0.5px solid transparent;
    .datepickerLabel {
      opacity: 0.6;
    }
  }
  &.focused {
    border: 1px solid var(--primary);
  }
}
.datepickerLabel {
  font-weight: 400;
  font-size: 11px;
  line-height: 1.4;
  margin-top: 1px;
  margin-right: 4px;
  color: var(--text-secondary);
}
.datepickerFormField {
  .mat-mdc-form-field-focus-overlay,
  .mdc-line-ripple,
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mat-mdc-text-field-wrapper.mdc-text-field {
    padding: 0;
    background: none;
  }
  .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
    min-height: auto;
    padding: 0;
    padding-bottom: 2px;
    width: auto;
  }
  &.range {
    .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
      width: 200px;
    }
  }
  .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
    font-size: 11px;
    font-weight: 400;
    color: var(--text-secondary);
  }
}
